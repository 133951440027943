import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Layout, Card, Space, Row, Col, Image, Typography } from 'antd';
//import Masonry from 'react-masonry-component';
import Masonry from 'react-responsive-masonry';

const { Meta } = Card;
const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const headerStyle = {
  textAlign: 'left',
  color: '#fff',
  height: 64,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#7dbcea',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#108ee9',
};
const siderStyle = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#3ba0e9',
};
const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#7dbcea',
};

function App() {
  return (
    <div className="App">      
      <Space direction="vertical" style={{width: '100%'}} size={16}>
        <Layout>
          <Header style={headerStyle}>
            {/* <Title level={3}>Rashid Wilson Frontend Mentor Challenges</Title> */}
            <Image 
              width={200}
              src='images/FrontendMentor-Logo-Desktop.svg'
            />
          </Header>
          <Content style={contentStyle}>
          
          <div style={{'margin-top': '10px'}}>
              <Col span={8} className='info-col'>
                <p className='info-text'>Rashid Wilson's <a href='https://www.frontendmentor.io/' target='_blank'>Frontend Mentor</a> challenge submissions.</p>
              </Col>
          </div>
          
          <Row gutter={16} className='row'>
          
          <Masonry
                columnsCount={3}
                gutter='10px'
            >              
               <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="Screenshot" src="images/InteractivePricingComponentChallenge-Screenshot.jpg" />}
                  className='card'
                >
                  {/* <Meta title="Interactive Pricing Component" description="www.instagram.com" /> */}
                  <Meta title="Interactive Pricing Component" description={<a href='https://shangum.github.io/Interactive-pricing-component-challenge-hub-FrontendMentor/' target='_blank'>View</a>} />                  
                </Card>
              </div>

              <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="Screenshot" src="images/SunnysideAgencyLandingPageChallenge-Screenshot.jpg" />}
                  className='card'
                >
                  <Meta title="Sunnyside Agency Landing Page" description={<a href='https://shangum.github.io/Sunnyside-Agency-Landing-Page-Challenge-Frontend-Mentor/' target='_blank'>View</a>} />
                </Card>
              </div>

              <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="Screenshot" src="images/TestimonialsGridSectionChallenge-Screenshot.jpg" />}
                  className='card'
                >
                  <Meta title="Testimonials Grid Section" description={<a href='https://shangum.github.io/Testimonials-Grid-Section-Challenge-Hub-FrontendMentor/' target='_blank'>View</a>} />
                </Card>
              </div>
              <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="Screenshot" src="images/HuddleLandingPage-Screenshot.jpg" />}
                  className='card'
                >
                  <Meta title="Huddle Landing Page" description={<a href='https://shangum.github.io/Huddle-Landing-Page-Challenge-Frontend-Mentor/' target='_blank'>View</a>} />
                </Card>
              </div>

              <div span={6} className='mason-item'>
                  <Card
                    hoverable
                    style={{ width: '90%' }}
                    cover={<img alt="Screenshot" src="images/FaqAccordianCard-Screenshot.jpg" />}
                    className='card'
                  >
                    <Meta title="FAQ Accordion Card" description={<a href='https://shangum.github.io/FAQ-Accordion-Card-Challenge-Frontend-Mentor/' target='_blank'>View</a>} />
                  </Card>
              </div>

              <div span={6} className='mason-item'>
                  <Card
                    hoverable
                    style={{ width: '90%' }}
                    cover={<img alt="Screenshot" src="images/IntroComponentWithSignUpForm-Screenshot.jpg" />}
                    className='card'
                  >
                    <Meta title="Intro Component With Sign Up Form" description={<a href='https://shangum.github.io/Intro-component-with-sign-up-form-challenge-hub-Frontend-Mentor/' target='_blank'>View</a>} />
                  </Card>
              </div>

              <div span={6} className='mason-item'>
                  <Card
                    hoverable
                    style={{ width: '90%' }}
                    cover={<img alt="example" src="images/StatsPreviewCardComponent-Screenshot.jpg" />}
                    className='card'
                  >
                    <Meta title="Stats Preview Card Component" description={<a href='https://shangum.github.io/Stats-Preview-Card-Component-Frontend-Mentor/' target='_blank'>View</a>} />
                  </Card>
              </div>

              <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="example" src="images/3ColumnPreviewCardComponent-Screenshot.jpg" />}
                  className='card'
                >
                  <Meta title="3-Column Preview Card Component" description={<a href='https://shangum.github.io/3-Column-Preview-Card-Component-Challenge-Frontend-Mentor/' target='_blank'>View</a>} />
                </Card>
              </div>

              <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: '90%' }}
                  cover={<img alt="example" src="images/OrderSummaryCard-Screenshot.jpg" />}
                  className='card'
                >
                  <Meta title="Order Summary Card" description={<a href='https://shangum.github.io/Order-Summary-Component-Challenge-Frontend-Mentor/' target='_blank'>View</a>} />
                </Card>
              </div>

              {/* <div span={6} className='mason-item'>
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={<img alt="example" src="images/StreetGirl.png" />}
                  className='card'
                >
                  <Meta title="Europe Street beat" description="www.instagram.com" />
                </Card>
              </div> */}

            </Masonry>
            </Row>

          </Content>
          {/* <Footer style={footerStyle}>Footer</Footer> */}
        </Layout>
        

      </Space>
    </div>
  );
}

export default App;
